import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a19b760c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "imgPreview"
};
var _hoisted_2 = {
  class: "imgbox"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_image = _resolveComponent("el-image");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: item
    }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_image, {
      class: "img",
      fit: "cover",
      "preview-src-list": _ctx.srcList,
      "initial-index": index,
      src: _ctx.baseUrl + item
    }, null, 8, ["preview-src-list", "initial-index", "src"])])]);
  }), 128))]);
}