import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { defineComponent, ref } from "vue";
import { Close } from "@element-plus/icons";
export default defineComponent({
  components: {
    Close: Close
  },
  props: {
    list: {
      type: String,
      default: "",
      require: true
    }
  },
  setup: function setup(props) {
    var baseUrl = process.env.VUE_APP_BASE_URL;
    var fileList = ref([]);
    var srcList = ref([]);
    fileList.value = props.list.split(',');
    props.list.split(',').forEach(function (item) {
      srcList.value.push(baseUrl + item);
    });
    return {
      fileList: fileList,
      baseUrl: baseUrl,
      srcList: srcList
    };
  }
});